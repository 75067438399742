import { DEFAULT_BASE_URL, INITILIZE_SESSION_PATH, INITILIZE_USER_PATH, SEND_LOGS_PATH } from '../utils';
import { consoleLogs, resetConsoleData } from '../lib/collectLog';
import { networkLogs, resetNetworkData } from '../lib/collectNetwork';
import { eventLogs, resetEventData } from '../lib/collectEvents';
import {
    sessionEvents, resetSessionEvents,
    setLastUploadedTime, logEvents,
    resetLogEvents, sequenceNumber,
    increaseSequenceNumber
} from './index'
import compression from '../lib/compression';

interface ApiInterface {
    createSession(data: object): void;
    sendUser(data: object): void;
    sendLogs(force: boolean, stopRecording: () => void): void;
    sendRequest(urlPath: string, data: object, method: string): void;
}

interface EventsPayload {
    domNavigationTimings?: object;
    sessionReplay: object[];
    consoleLogs: object[];
    networkLogs: object[];
    eventLogs: object[];
    events: object[];
    sequence: number,
}

export default class Api implements ApiInterface {
    private readonly projectKey: string;
    private readonly sessionId: string;
    private readonly tabId: string;
    private domNavigationTimingsSent: boolean;
    constructor(projectKey: string, sessionId: string, tabId: string) {
        this.projectKey = projectKey
        this.sessionId = sessionId
        this.tabId = tabId
        this.domNavigationTimingsSent = false;
        this.domNavigationTimingsSent = false;
    }
    createSession(data: object): void {
        // send prodcutkey, system info, sessionid, tabid
        this.sendRequest(INITILIZE_SESSION_PATH, data)
    }
    sendUser(data: object): void {
        //send user pk and session id to connect with user with session and user traits
        this.sendRequest(INITILIZE_USER_PATH, data)
    }
    sendLogs(force = false, stopRecording: () => void): void {

        let eventsData: EventsPayload = {
            sessionReplay: sessionEvents,
            consoleLogs: consoleLogs,
            networkLogs: networkLogs,
            eventLogs: eventLogs,
            events: logEvents,
            sequence: sequenceNumber,
        };

        if (document.readyState === "complete" && !this.domNavigationTimingsSent) {
            const entries = performance.getEntriesByType("navigation");
            entries.forEach((entry) => {
                eventsData = {
                    ...eventsData,
                    domNavigationTimings: entry.toJSON(),
                }
            });
        }

        const hasAnyData = eventsData.sessionReplay.length > 0 ||
            eventsData.consoleLogs.length > 0 ||
            eventsData.networkLogs.length > 0 ||
            eventsData.eventLogs.length > 0 ||
            eventsData.events.length > 0

        // check if the time elapsed is greater than the polling interval and 
        // if the size of the data is greater than threshold
        if (hasAnyData || force) {

            if (eventsData.hasOwnProperty('domNavigationTimings')) {
                this.domNavigationTimingsSent = true
            }

            this.sendRequest(SEND_LOGS_PATH, eventsData).then(responseCode => {
                if (responseCode === 401) {
                    // Stop recording.
                    stopRecording()
                    console.warn("UserExperior: the recording has been stopped, the version key is invalid or your session limit has reached.")
                }
            }).catch(error => {
                console.log(error)
            })
            setLastUploadedTime()
            resetSessionEvents()
            resetConsoleData()
            resetNetworkData()
            resetEventData()
            resetLogEvents()
            increaseSequenceNumber()
        }
    }
    async sendRequest(urlPath: string, data: object, method = "POST"): Promise<number> {
        // send request to server with the preflight timestamp
        let requestBody: any = {
            versionKey: this.projectKey,
            sessionId: this.sessionId,
            tabId: this.tabId,
            timestamp: Math.floor(Date.now() / 1000),
            ...data,
        }

        let headers: any = {
            'Content-Type': 'application/json',
            'ue-vk': this.projectKey,
        }

        const compressedPayload = await compression(JSON.stringify(requestBody))
        if (compressedPayload.length > 0) {
            headers = {
                ...headers,
                'Content-Encoding': 'gzip'
            }
            requestBody = compressedPayload
        }

        try {
            if (!headers.hasOwnProperty('Content-Encoding')) {
                requestBody = JSON.stringify(requestBody)
            }
            const response = await fetch(DEFAULT_BASE_URL + urlPath, {
                method: method,
                headers: headers,
                body: requestBody,
            })
            return response.status
        } catch (error) {
            console.log(error)
            return 0
        }
    }
}