import { EventType, record, IncrementalSource, MouseInteractions } from "../../recorder";
import { eventWithTime } from "../../recorder/typings/types";
import { removeDeadClick } from "./checkDeadClicks";
import { newListener } from "./collectEvents";

let lastEvent: eventWithTime;

export const processEvent = async (event: eventWithTime) => {
    
    if(event.type == EventType.IncrementalSnapshot && event.data.source == IncrementalSource.Mutation && lastEvent) {
        if(lastEvent.type == EventType.IncrementalSnapshot && lastEvent.data.source == IncrementalSource.MouseInteraction) {
            removeDeadClick();
        }
    }

    if(event.type == EventType.IncrementalSnapshot && event.data.source == IncrementalSource.MouseInteraction && event.data.type == MouseInteractions.Click) {
        if(event.data.x !== 0 && event.data.y !== 0) {
            const node = record.mirror.getNode(event.data.id) as HTMLElement | HTMLInputElement;
            const timestamp = event.timestamp;
            newListener(node, timestamp, "click");
        }
    }

    if(event.type == EventType.IncrementalSnapshot && event.data.source == IncrementalSource.Input) {
        const node = record.mirror.getNode(event.data.id) as HTMLElement | HTMLInputElement;
        const timestamp = event.timestamp;
        newListener(node, timestamp, "form-change", event.data.text);
        removeDeadClick();
    }

    lastEvent = event;
}