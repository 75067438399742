export const IN_BROWSER = !(typeof window === "undefined");

let APP_ENV = 'Production'
export const SDK_VERSION = "0.2.55"

let host = 'http://localhost:8080';

if (APP_ENV === 'Production') {
    host = 'https://observability.dev.devrev-eng.ai/web/ingest';
}

export const DEFAULT_BASE_URL = host + '/api/ingest'

//individual url paths
export const INITILIZE_SESSION_PATH = '/startSession/'
export const INITILIZE_USER_PATH = '/setIdentifier/'
export const SEND_CUSTOM_METADATA = '/addMetadata/'
export const SEND_LOGS_PATH = '/logData/'

// to restrict, sending request and response body if it reaches certain limit
export const DEFAULT_NETWORK_BODY_SIZE = 256 //in kB or Kilo Bytes

// send data after reaching certain threshold
export const DEFAULT_POLLING_INTERVAL = 15 //in seconds
export const MAX_SIZE_THRESHOLD = 512 //in kB or Kilo Bytes

// session throttling
export const DEFAULT_INACTIVITY_THROTTLE = 30 * 60 // in seconds
export const DEFAULT_INACTIVITY_COOLING_PERIOD = 300 //in millseconds

export const DEFAULT_VISIBILITY_THROTTLE = 900 // in seconds
export const DEFAULT_SESSION_THROTTLE = 20 // in seconds
export const DEFAULT_RAGE_THROTTLE = 200 // in ms

export const USER_IDENTIFIER = 'userIdentifier';
export const USER_PROPERTIES = 'userProperties';
export const CUSTOM_METADATA = 'customMetadata';
export const UE_SESSION_ID = 'ue_session';
export const UE_LAST_UPLOAD = 'ue_last_upload';
export const UE_SESSION_EXPIRE = 'ue_session_expire';
export const UE_SESSION_ACTIVE = 'ue_session_active';
export const RESTART_SESSION = 'restart_session';
export const UE_TAB = 'ue_tab';
export const UE_API_SUBDOMAIN = 'webmonitoringapi';


//default session replay options
export const DEFAULT_SESSION_REPLAY_BLOCK_CLASS = 'ue-block'
export const DEFAULT_SESSION_REPLAY_MASK_CLASS = 'ue-mask'
export const CAPTURE_IMAGES_INLINE = false
export const CAPTURE_INLINE_STYLESHEET = true
export const MASK_ALL_INPUTS = false
export const MASK_INPUT_CLASS = 'ue-input-mask'
export const DEFAULT_MASK_INPUT_OPTIONS = {
    password: true,
    email: true,
    tel: true
}

export const getStorageItem = (key: string): any => {
    return localStorage.getItem(key);
};

export const setStorageItem = (key: string, value: string): void => {
    localStorage.setItem(key, value);
};

export const clearItem = (key: string, value: string): void => {
    localStorage.setItem(key, value);
};

export const getDurableStorageItem = (key: string): any => {
    return localStorage.getItem(key) || sessionStorage.getItem(key);
};

export const setDurableStorageItem = (key: string, value: string): void => {
    localStorage.setItem(key, value);
    sessionStorage.setItem(key, value);
};

export const clearDurableStorageItem = (key: string): void => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
};


export const encodeBase64 = (data: any) => {
    return Buffer.from(data).toString('base64');
}
export const decodeBase64 = (data: any) => {
    return Buffer.from(data, 'base64').toString('ascii');
}