import { DEFAULT_RAGE_THROTTLE } from "../utils";

interface pageChangeInterface {
    event_type: string, 
    pageUrl: string;
    pageName: string;
    timestamp: number;
}

export let eventLogs: Array<any> = []
export let pageUrl: string = document.location.href
export let lastClickArrayIndex: number
let lastClickTimestamp: number

export const resetEventData = (): void => {
    eventLogs = []
}

// Function to push page changes.
const pushPageChange = (pageUrl: string, pageName: string): void => {
    // Using the page change naming convention as exception
    const pageChange: pageChangeInterface = {
        event_type: "nav",
        pageUrl: pageUrl,
        pageName: pageName,
        timestamp: Date.now()
    }
    eventLogs.push(pageChange);
}

const getElementLabel = (element: HTMLElement): string => {
    try {
        if (element instanceof HTMLAnchorElement || element instanceof HTMLHeadingElement || element.tagName == "BUTTON") {
            return truncate((element.title || element.innerText || element.tagName).trim(), 25);
        }

        if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
            const buttons: string[] = ["button", "reset", "submit"];

            if (element.type != null && buttons.includes(element.type)) {
                return truncate((element.value || element.name || element.tagName).trim(), 25);
            }

            if (element.getAttribute("placeholder") != null) {
                return element.getAttribute("placeholder") as string;
            }
            const eleId = element.getAttribute("id");
            if (eleId != null) {
                const label = document.querySelector(`[for='${eleId}']`) as HTMLElement | null;
                if (label != null) {
                    return (label.innerText || element.tagName).trim();
                }
            }
            if (element.getAttribute("name") != null) { return element.getAttribute("name") as string; }
            else if (element.getAttribute("class") != null) { return element.getAttribute("class") as string; }
            else { return truncate((element.title || element.tagName).trim(), 25); }
        }
        else {
            return element.tagName;
        }
    } catch (e) {
        console.warn("Error in fetching label");
        return "Element";
    }
}

const truncate = (innerText: string, length: number) =>
    innerText && innerText.length > length
        ? `${innerText.substring(0, length)}...`
        : innerText;

export const newListener = (node: HTMLElement | HTMLInputElement | null, timestamp: number, eventType: string, inputValue?: string) => {
    if(eventType == "click") {
        if(pageUrl !== document.location.href) {
            pageUrl = document.location.href
            pushPageChange(pageUrl, document.title);
        }
        const eventPush: any = {
            event_type: eventType,
            target: node?.nodeName || "",
            label: (node ? getElementLabel(node) : "") || "",
            value: truncate(node?.innerText || "", 50) || "",
            timestamp: timestamp,
            epochTime: Math.floor(Date.now() / 1000),
            isDead: true,
            pageUrl: document.location.href,
            pageName: document.title,
        }
        if(!eventLogs.length && eventPush.hasOwnProperty('isDead')) {
            delete eventPush['isDead'];
        }
        if(lastClickTimestamp && (timestamp - lastClickTimestamp) <= DEFAULT_RAGE_THROTTLE) {
            eventPush.isRage = true
        }
        lastClickTimestamp = timestamp
        const index = eventLogs.push(eventPush);
        lastClickArrayIndex = index - 1
    }

    if(eventType == "form-change" && inputValue && inputValue !== "on") {
        const eventPush = {
            event_type: eventType,
            target: node?.nodeName || "",
            label: (node ? getElementLabel(node) : "") || "",
            value: inputValue,
            timestamp: timestamp,
            epochTime: Math.floor(Date.now() / 1000),
        }
        eventLogs.push(eventPush);
    }
}
